import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: fixed;
  background-color: #F2F2F2;
  color: #3A3A3A;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;
  transform: translateX(100%);
  &.open {
    pointer-events: all;
    opacity: 1;
    transform: none;
  }
`

const Inner = styled.div`
  padding: 6rem 4rem 1rem 2rem;
  display: flex;
  flex-direction: column;
`

const Border = styled.div`
  border: 1px solid #CFCFCF;
  margin-block: 0.5rem;
  margin-inline: -1rem;
`

const Head = styled.div`
  font-size: 1rem;
  line-height: 2.5rem;
  letter-spacing: 0.1em;
@media (max-width: 40rem){
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
`

const Item = styled.a`
  font-size: 1.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  @media (max-width: 40rem){
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 400; 
  }
`

type MenuProps = {
  open: boolean
}
export const Menu: FunctionComponent<MenuProps> = ({open}) => {
  return <Outer className={classNames({open})}>
    <Inner>
      <Border />
      <Head>MEMBER</Head>
      <Item href="/shop/customers/sign_up">新規会員登録</Item>
      <Item href="/shop/customers/sign_in">ログイン</Item>
      <Border />
      <Head>PRODUCTS</Head>
      <Item href="/shop/products/BI0001_BC_F_na">BiRyu ダーマチャージセラム</Item>
      <Item href="/shop/products/BI0002_EC_F_na">BiRyu ダーマチャージスポットクリーム</Item>
      <Border />
      <Head>ABOUT</Head>
      <Item href="/shop/base_info">当サイトについて</Item>
      <Item href="/shop/information_categories/news">お知らせ</Item>
      <Border />
      <Head>SERVICE</Head>
      <Item href="/shop/customer_term">利用規約</Item>
      <Item href="https://endeavour-co.jp/agreement/">個人に関する情報の取扱いについて</Item>
      <Item href="/shop/law_info">特定商取引法に基づく表記</Item>
      <Item href="/shop/contact">お問い合わせ</Item>
      <Item href="/shop/faq">よくあるご質問</Item>
      <Border />
    </Inner>
  </Outer>
}