import { isNotNullish } from './type-check'

const findRoots = (el?: Document | Element | ShadowRoot): ShadowRoot[] => {
  const target = el ?? document
  return [
    target,
    ...Array.from(target.querySelectorAll('*'))
  ].flatMap(el => {
    return (el instanceof Element && el.shadowRoot) ? [el.shadowRoot, ...findRoots(el.shadowRoot)] : []
  })
}

export const querySelectorWithShadow = (selector: string) => {
  return [document, ...findRoots()].flatMap(root => root.querySelector(selector)).filter(isNotNullish)[0] ?? null
}

export const querySelectorAllWithShadow = (selector: string) => {
  return [document, ...findRoots()].map(root => Array.from(root.querySelectorAll(selector))).flat(1)
}