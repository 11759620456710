import * as React from "react";
import type { SVGProps } from "react";
const SvgMenuBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={19}
    fill="none"
    viewBox="0 0 26 19"
    {...props}
  >
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      d="M1 1h24M1 9.5h24M1 18.5h24"
    />
  </svg>
);
export default SvgMenuBar;
