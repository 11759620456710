import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Menu } from './menu'
import classNames from 'classnames'
import SvgBag from '../generated/svg/bag'
import SvgPerson from '../generated/svg/person'
import SvgLogo from '../generated/svg/logo'
import SvgMenuBar from '../generated/svg/menu-bar'
import { SvgCross } from './svg'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: opacity 0.4s, transform 0.4s, color 0.4s;
  pointer-events: none;
  color: #181F6F;

  &.hide {
    transform: translateY(-100%);
    opacity: 0;
  }
  &.white {
    color: white;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: stretch;
  padding: 2.5rem;
  gap: 1.5rem;
  @media (max-width: 40rem){
    padding: 1.75rem;
  }
`

const Logo = styled.a`
  margin-right: auto;
  display: flex;
  align-items: center;
  pointer-events: initial;

  svg {
    height: 2.125rem;
    width: auto;
    display: block;
    @media (max-width: 40rem){
      height: 1.9375rem;
    }
  }
`

const Navigator = styled.div`
  display: flex;
  gap: 1rem;
  align-items: stretch;
  pointer-events: initial;
  @media (max-width: 40rem){
    display: none;
  }
`

const NavigatorItem = styled.a`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: white;
    display: block;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.6s;
  }
  &:hover &::after {
    transform: none;
    transform-origin: 0 0;
  }
  @media (max-width: 40rem){
    font-size: 1.375rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: stretch;
  pointer-events: all;
`

const Button = styled.a`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: color 0.4s;
  .open & {
    color: white
  }
  svg {
    display: block;
    height: 1.625rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.25rem;
      width: auto;
    }
  }
`

const MenuButton = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 200;
  transition: color 0.4s;
  cursor: pointer;
  &.open {
    color: #181F6F;
  }
  svg {
    display: block;
    height: 1.25rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1rem;
      width: auto;
    }
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [open, setOpen] = useState(false)
  const [white, setWhite] = useState(false)
  const outerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      const scrollY = window.scrollY
      if(scrollY === 0 || scrollY < lastScrollY){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const {height} = outer.getBoundingClientRect()
        document.body.style.setProperty('--header-height', `var(--hide-header-height, ${height}px)`)
        setHeaderHeight(height)
      }
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      listener()
      return () => {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    const topBanner = querySelectorWithShadow('#top-banner')
    if (topBanner) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setWhite(entry.isIntersecting)
          })
        },
        { threshold: [0] }
      )
      observer.observe(topBanner)
      return () => {
        observer.disconnect()
      }
    }
  })
  return <>
    <Outer ref={outerRef} className={classNames({hide: !open && hide, open, white})}>
      <Inner>
        <Logo href="/">
          <SvgLogo />
        </Logo>
        <ButtonContainer>
          <Button href="/shop/cart">
            <SvgBag />
          </Button>
          <Button href="/shop/customer">
            <SvgPerson />
          </Button>
          <MenuButton className={classNames({open})} onClick={() => setOpen(v => !v)}>
            {
              open ? <SvgCross /> : <SvgMenuBar />
            }
          </MenuButton>
        </ButtonContainer>
        <Menu open={open} />
      </Inner>
    </Outer>
  </>
}